<template>
  <div>
  <b-card class="blog-edit-wrapper" :title="tituloForm">
    <validation-observer ref="simpleRules">
      <b-form>
        <!-- Tipo de publicacion -->
        <b-row v-if="!id">
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Tipo de publicacion">
                  <b-form-radio-group
                    v-model="tipoPublicacion"
                    :options="tipoPublicacionOptions"
                    class="demo-inline-spacing"
                    name="radio-inline"
                    @input="getipoPublicacion"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="invoice-spacing">
          </b-col>
        </b-row>
        <!-- Titulo y Url -->
        <b-row>
          <!-- titulo -->
          <b-col md="6" cols="12">
            <b-form-group
                label-for="Titulo"
            >
                <label >Titulo <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Titulo"
                  rules="required"
                  >
                  <b-form-input
                      id="titulo"
                      v-model="titulo"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Titulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-for="error in errores.titulo" :key="error" class="text-danger">{{ error }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" cols="12">
            <b-form-group
                label="Url"
                label-for="Url"
            >
              <b-form-input
                  id="url"
                  disabled
                  v-model="url"
                  placeholder="Url"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label-for="Fecha Publicacion"
            >
              <label >Fecha Publicación <span class="text-danger">(*)</span></label>
              <flat-pickr
                v-model="published_at"
                class="form-control"                
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', locale: 'es' }"
              />
              <small v-for="error in errores.published_at" :key="error" class="text-danger">{{ error }}</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label-for="Estado"
            >
              <label >Estado <span class="text-danger">(*)</span></label>
              <v-select
                id="blog-edit-estado"
                v-model="estado"
                label="title"
                :disabled="!($hascan('estadisticas'))"
                :options="estadoOpciones"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
    
          <b-col md="4">
            <b-form-group
                label-for="Categorias"
              >
              <label >Categoria <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Categoria"
                rules="required"
                >
                  <v-select
                    id="blog-edit-categoria"
                    v-model="categoria_id"
                    label="title"
                    :state="errors.length > 0 ? false:null"
                    :options="categoriaOpciones"
                    :clearable="false"
                  >
                    <template v-if="$hascan('categorias')" #list-header>
                      <li
                        v-b-modal.modal-categoria @click="accionCategoria='registrar'"
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Nueva Categoria</span>
                      </li>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-for="error in errores.categoria_id" :key="error" class="text-danger">{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        
        <!-- CAMPOS PARA LA ENTRADA GENERADA -->
        <b-row v-if="tipoPublicacion">
          <b-col cols="12">
            <hr class="invoice-spacing">
            <!-- CONSULTA API -->
            <b-row>
              <b-col md="2">
                <b-form-group label="Departamento" label-for="Departamento">
                  <v-select
                    v-model="iddepartamento"
                    label="nombre"
                    :options="departamentoOpciones"
                    :clearable="false"
                    :key="iddepartamento"
                    :reduce="op => op.id"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group label="Municipio" label-for="Municipio">
                  <v-select
                    :disabled="iddepartamento==0"
                    v-model="idmunicipio"
                    label="nombre"
                    :options="municipioOpciones"
                    :clearable="false"
                    :key="idmunicipio"
                    :reduce="op => op.id"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group label="Clase delito" label-for="Clase delito">
                  <v-select
                    v-model="idclasedelito"
                    label="nombre"
                    :options="claseDelitoOpciones"
                    :clearable="false"
                    :key="idclasedelito"
                    :reduce="op => op.id"
                    @input="listarDelitosPorClase(idclasedelito)"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Delito" label-for="Delito">
                  <v-select
                    v-model="iddelito"
                    label="nombre"
                    :options="delitoOpciones"
                    :clearable="false"
                    :key="iddelito"
                    :reduce="op => op.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group label="Tentativa" label-for="Tentativa">
                  <v-select
                    v-model="idtentativa"
                    label="nombre"
                    :options="tentativaOpciones"
                    :clearable="false"
                    :key="idtentativa"
                    :reduce="op => op.id"
                    
                  />
                </b-form-group>
              </b-col>
              
              <b-col md="2">
                <b-form-group label="Carga" label-for="Carga">
                  <v-select
                    v-model="idcarga"
                    label="nombre"
                    :options="cargaOpciones"
                    :clearable="false"
                    :key="idcarga"
                    :reduce="op => op.id"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group label="Fecha de:" label-for="Fecha de:">
                  <flat-pickr
                    :disabled="idcarga==1"
                    :style="[idcarga==1 ? {'background': '#efefef'} : '']"
                    
                    v-model="fechadel"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group label="Fecha al:" label-for="Fecha al:">
                  <flat-pickr
                    :disabled="idcarga==1"
                    :style="[idcarga==1 ? {'background': '#efefef'} : '']"
                    v-model="fechaal"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group label="Tipo grafico" label-for="Tipo grafico">
                  <v-select
                    v-model="tipo_grafico"
                    label="nombre"
                    :options="tipo_graficoOpciones"
                    :clearable="false"
                    :key="tipo_grafico"
                    :reduce="op => op.id"
                    
                  />
                </b-form-group>
              </b-col>
              
              <b-col md="2">
                <b-form-group label="Tipo consulta" label-for="Tipo consulta">
                  <v-select
                    v-model="estatico"
                    label="nombre"
                    :options="estaticoOpciones"
                    :clearable="false"
                    :key="estatico"
                    :reduce="op => op.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right" md="12">
                <b-form-group
                >
                  <b-button
                    v-if="idcarga==1"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    @click="generarEstadistica()"
                  >
                    Generar
                  </b-button>
                  <b-button
                    v-else
                    :disabled="fechaal==''||fechadel==''"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    @click="generarEstadisticaFechas()"
                  >
                    Generar
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- FIN DE CONSULTA API -->

            <!-- MOSTRAR GRAFICO -->
            <b-row>
              <b-col v-if="iddelito!=0" cols="12">
                <b-card-body class="text-center">
                  <span><strong>DELITO: </strong>{{delito}}</span>
                </b-card-body>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-card-body>
                  <chartjs-component-bar-chart
                    v-if="tipo_grafico==1"
                    :height="400"
                    :chartData="chartData"
                    :options="options"
                  />
                  <chartjs-component-horizontal-bar-chart
                    v-else
                    :height="400"
                    :chartData="chartData"
                    :options="options"
                  />
                </b-card-body>
              </b-col>
              <b-col cols="12">
                <b-card-body>
                  <b-card-body v-if="iddelito!=0" class="text-center">
                    <span><strong>DELITO: </strong>{{delito}}</span>
                  </b-card-body>
                  <table class="table">
                    <thead>
                      <th>
                        <span v-if="iddelito==0 || iddepartamento!=0">Delitos</span>
                        <span v-else>Departamento</span>
                      </th>
                      <th>Casos Cerrados</th>
                      <th>Casos Abiertos</th>
                      <th>Casos Totales</th>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in json_respuesta" :key="index">
                        <td>
                          <span v-if="iddelito==0 || iddepartamento!=0">{{item.Delito}}</span>
                          <span v-else>{{item.departamento}}</span>
                        </td>
                        <td>{{item.casos_cerrados}}</td>
                        <td>{{item.casos_abiertos}}</td>
                        <td>{{item.casos_total}}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-col>
            </b-row>
            <!-- FIN MOSTRAR GRAFICO -->
          </b-col>
        </b-row>
        <!-- FIN CAMPOS PARA LA ENTRADA GENERADA -->

        <!-- CAMPOS DE LA ENTRADA -->
        <b-row>
          <!-- Contenido -->
          <b-col cols="12">
              <b-form-group
                  label=""
                  label-for=""
              >
                <b-button v-b-modal.modal-seleccionar-archivo variant="flat-dark" size="sm">
                  <feather-icon
                    icon="ImageIcon"
                    class="mr-50"
                  />
                  <span class="align-left">Medios</span>
                </b-button>
                <validation-provider
                    #default="{ errors }"
                    name="Contenido"
                    rules="required"
                    >
                    <CkEditorPersonalizado ref="ckeditor" v-model="contenido"></CkEditorPersonalizado>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-for="error in errores.contenido" :key="error" class="text-danger">{{ error }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="mb-2">
            <div class="border rounded p-2">
              <h5 class="text-center"><b>Foto Principal</b></h5>
              <div class="text-center">
                <b-form-group
                  label=""
                  label-for=""
                  >
                  <div class="box-imagen box-imagen1 border rounded">
                      <b-img
                        v-if="imagen"
                        :src="imagen" 
                        center
                        fluid
                        thumbnail
                        class="rounded"
                        alt="Card image cap"
                      />
                        
                  </div>
                </b-form-group>
                <!-- v-b-modal.modal-seleccionar-imagen  -->
                <b-button 
                    :disabled="tipoPublicacion" 
                    v-b-modal.modal-seleccionar-imagen-estadistica
                    variant="flat-dark" 
                    size="sm"
                  >
                    <feather-icon
                      icon="ImageIcon"
                      class="mr-50"
                    />
                  <span class="align-middle">Elegir Imagen</span>
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <!-- Descripcion -->
                <b-form-group
                    label="Descripcion"
                    label-for="descripcion"
                >
                <CkEditorPersonalizado
                  ref="ckeditorDescripcion"
                  v-model="descripcion"
                ></CkEditorPersonalizado>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- submit -->
        <b-row>
          <b-col cols="12" class="mt-50">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              split
              text="Guardar"
              variant="primary"
              class="mr-1"
              @click="validar(),guardarSalir=true"
              right
            >
              <b-dropdown-item @click="validar(),guardarSalir=false"> 
                Previsualizar
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'estadistica' }"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--Componente Categoria -->
    <CategoriaNuevoEditar 
      :accion="accionCategoria" 
      :item="itemCategoria"
      :tipoEntradaId="tipoEntradaId"  
      @cerrarComponente="cerrarComponenteCategoria" 
      @actualizarLista="actualizarListaCategoria" >
    </CategoriaNuevoEditar>
    <!--Componente Medios -->
    <MediosComponent @enviarMedia="recibirMedia"></MediosComponent>
    <!--Componente Imagen --> 
    <!-- <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent> -->
    <!--Componente Imagen Estadistica --> 
    <EstadisticaComponent @enviarImagen="recibirImagen"></EstadisticaComponent>
  </b-card>
  </div>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody, BCardTitle, BCardSubTitle, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BImg, BFormFile, BFormDatepicker, BDropdown, BDropdownItem, BFormRadioGroup 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import CategoriaNuevoEditar from '@/views/aplicaciones/configuracion/categoria/CategoriaNuevoEditar.vue';
import MediosComponent from '@/componentes/MediosComponent.vue';
// import ImagenComponent from '@/componentes/ImagenComponent.vue';
import EstadisticaComponent from '@/componentes/EstadisticaComponent.vue';
import CkEditorPersonalizado from '@/componentes/CkEditorPersonalizado.vue';
import axios from 'axios'
import ChartjsComponentBarChart from './ChartjsComponentBarChart.vue'
import ChartjsComponentHorizontalBarChart from './ChartjsComponentHorizontalBarChart.vue'

const Spanish = require('flatpickr/dist/l10n/es');
// localize("es", es);

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    BImg, 
    BFormFile,
    BFormDatepicker,
    flatPickr,
    BDropdown, BDropdownItem,BFormRadioGroup,

    CategoriaNuevoEditar,
    MediosComponent,
    // ImagenComponent,
    EstadisticaComponent,
    CkEditorPersonalizado,
    ChartjsComponentBarChart,
    ChartjsComponentHorizontalBarChart
  },
  directives: {
    Ripple,
  },
  data () {
      return {
          //datos de entrada
          id:null,
          titulo:'',
          url:'',
          descripcion:"",
          contenido:"",
          published_at:null,
          imagen:'',
          estado: { id: 2, title: 'Borrador' },
          estadoOpciones:[
              { id: 1, title: 'Publicado' },
              { id: 2, title: 'Borrador' },
              { id: 3, title: 'Papelera' }
          ],
          tipoPublicacion:true,
          tipoPublicacionOptions: [
            { text: 'Entrada Generada', value: true },
            { text: 'Entrada Normal', value: false },
          ],
          slugCategoria:'',
          categoria_id:'',
          categoriaOpciones: [],
          tipoEntradaId:5,
          //fin datos de entrada
          required,
          submitted: false,
          guardarSalir:true,
          errores:[],
          editorOpcion:{
            placeholder: 'Ingrese el contenido aqui ...'
          },
          //editor: ClassicEditor,
          editorConfig: {
          },
          accionCategoria:'',
          itemCategoria:[],

          // datos de api fiscalia
          idcarga:1,
          cargaOpciones:[
              { id: 1, nombre: 'Carga activa' },
              { id: 2, nombre: 'Por fecha cierre' },
              { id: 3, nombre: 'Por fecha registro' }
          ],

          iddepartamento:0,
          departamentoOpciones:[
              { id: 0, nombre: 'Todos' },
              { id: 1, nombre: 'Chuquisaca' },
              { id: 2, nombre: 'La Paz' },
              { id: 3, nombre: 'Cochabamba' },
              { id: 4, nombre: 'Oruro' },
              { id: 5, nombre: 'Potosi' },
              { id: 6, nombre: 'Tarija' },
              { id: 7, nombre: 'Santa Cruz' },
              { id: 8, nombre: 'Beni' },
              { id: 9, nombre: 'Pando' },
          ],

          idmunicipio:0,
          municipioOpciones:[
              { id: 0, nombre: 'Todos' },
          ],

          iddelito:0,
          delitoOpciones:[
              { id: 0, nombre: 'Todos' },
          ],
          delitoOpcionesAux:[],
          idclasedelito:0,
          claseDelitoOpciones:[
              { id: 0, nombre: 'Todos' },
          ],
          fechadel:'',
          fechaal:'',
          json_respuesta:[],
          json_consulta:'',
          estatico:0,
          estaticoOpciones:[
              { id: 0, nombre: 'estatico' },
              { id: 1, nombre: 'dinamico' },
          ],
          tipo_grafico:0,
          tipo_graficoOpciones:[
              { id: 0, nombre: 'Barras horizontales' },
              { id: 1, nombre: 'Barras verticales' },
          ],

          idtentativa:0,
          tentativaOpciones:[
              { id: 0, nombre: 'Todos' },
              { id: 1, nombre: 'Tentativa' },
              { id: 2, nombre: 'No tentativa' },
          ],
          
          options: {
            elements: {
              rectangle: {
                borderWidth: 1,
                borderSkipped: 'top',
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  ticks: {
                    min: 0,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  ticks: {
                    min: 0,
                  },
                },
              ],
            },
          },
          chartData: null,
          estadoControl:true,
          municipio:'Todos',
          departamento:'Todos',
          delito:'Todos',
          carga:'Carga activa',
          tentativa:'Todos',
          clasedelito:'Todos',
          
      }
  },

  computed: {
    tituloForm() {
      return this.id ? "Editar Estadistica" : "Nueva Estadistica";
    },
  },
  watch: {
    $route: function(val) {
      if (val.params.id) {
        this.editarEntrada(this.$route.params.id);
      }else{
        this.limpiar();
      }
    },
    iddepartamento: function() {
        if (this.iddepartamento!=0) {
            this.listarMunicipios();
        }else{
          this.municipioOpciones=[
              { id: 0, nombre: 'Todos' },
          ];
          this.idmunicipio=0;
        }  
    },
    idcarga: function() {
      if (this.idcarga==1) {
        this.fechadel='';
        this.fechaal='';
      }
    },
    iddelito: function() {
      if (this.iddelito!=0) {
        const delitoAux=this.delitoOpciones.find(delito => delito.id === this.iddelito);
        this.delito=delitoAux.nombre;
      }
    },
    idclasedelito: function() {
      if (this.idclasedelito!=0) {
        const claseDelitoAux=this.claseDelitoOpciones.find(clasedelito => clasedelito.id === this.idclasedelito);
        this.clasedelito=claseDelitoAux.nombre;
      }else{
        this.delitoOpciones=this.delitoOpcionesAux;
        this.iddelito=0;
      }  
    },
  },
  created(){  
    this.listarDelitos();        
    this.listarCategorias();
    this.date();
    this.listarClaseDelitos();
  },  
  methods: {
    getipoPublicacion(){
      if (!this.tipoPublicacion) {
        this.iddepartamento=0;
        this.iddelito=0;
        this.idcarga=1;
        this.fechadel='';
        this.fechaal='';
        this.json_respuesta=[];
        this.json_consulta='';
        this.tipo_grafico=0;
        this.estatico=0;
        this.idtentativa=0;
        this.idclasedelito=0;
        this.fillData();
      }
    },
    listarMunicipios(){
        const url = this.mixing.app_url_estadistica+'/catalogos/municipios/v1/'+this.iddepartamento;
        axios.get(url)
        .then(res => {
          this.municipioOpciones=[
              { id: 0, nombre: 'Todos' },
          ];
          const posts = res.data.response.map(g => ({id:g.id,nombre:g.nombre,}));
          this.municipioOpciones = this.municipioOpciones.concat(posts);
          if (this.estadoControl) {
            if (this.id) {
              this.estadoControl=false;
            }else{
              this.idmunicipio=0;
            }
          }else{
            this.idmunicipio=0;
          }
        })
        .catch(err =>{

        });
    },

    listarDelitos(){
        const url = this.mixing.app_url_estadistica+'/catalogos/delitos/v1';
        axios.get(url)
        .then(res => {
          this.delitoOpciones = this.delitoOpciones.concat(res.data.response);
          this.delitoOpcionesAux=this.delitoOpciones;
        })
        .catch(err =>{

        });
    },

    listarDelitosPorClase(idclasedelito){
      this.iddelito=0;
      const delitoOpcionesAux=this.delitoOpcionesAux;
      delitoOpcionesAux[0].clasedelito_id=idclasedelito
      this.delitoOpciones = delitoOpcionesAux.filter(function (el) {
        return el.clasedelito_id == idclasedelito
      });
      
    },

    listarClaseDelitos(){
        const url = this.mixing.app_url_estadistica+'/catalogos/clasedelitos/v1';
        axios.get(url)
        .then(res => {
          this.claseDelitoOpciones = this.claseDelitoOpciones.concat(res.data.response);
        })
        .catch(err =>{

        });
    },

    generarEstadistica(){
        const url = this.mixing.app_url_estadistica+'/catalogos/estadisticascaso/v1';
        axios.post(url,{
            'idDepartamento':this.iddepartamento,
            'idMunicipio':this.idmunicipio,
            'idDelito':this.iddelito,
            'idClaseDelito':this.idclasedelito,
            'idCarga':this.idcarga,
            'idTentativa':this.idtentativa,
        })
        .then(res => { 
          this.json_consulta=url;
          this.json_respuesta=res.data.response;        
          this.fillData();
        })
        .catch(err =>{
            console.log(err)
        });
    },
    
    generarEstadisticaFechas(){
        const url = this.mixing.app_url_estadistica+'/catalogos/estadisticascaso/v1';
        axios.post(url,{
            'idDepartamento':this.iddepartamento,
            'idMunicipio':this.idmunicipio,
            'idDelito':this.iddelito,
            'idClaseDelito':this.idclasedelito,
            'idTentativa':this.idtentativa,
            'idCarga':this.idcarga,
            'fecha1':this.fechadel,
            'fecha2':this.fechaal,
        })
        .then(res => {
          this.json_consulta=url;
          this.json_respuesta=res.data.response;        
          this.fillData();
        })
        .catch(err =>{
            console.log(err)
        });
    },

    fillData(){
        if (this.iddelito && this.iddepartamento==0) {
          var label = this.json_respuesta.map(g => (g.departamento));
        }else{
          var label = this.json_respuesta.map(g => (g.Delito));
        }
        const c_abiertos = this.json_respuesta.map(g => (g.casos_abiertos));
        const c_cerrados = this.json_respuesta.map(g => (g.casos_cerrados));
        const c_totales = this.json_respuesta.map(g => (g.casos_total));
        this.chartData={
        labels: label,
        datasets: [
          {
            label: 'Casos cerrados',
            backgroundColor: '#f87979',
            data: c_cerrados,
          },
          {
            label: 'Casos abiertos',
            backgroundColor: '#6B84F5',
            data: c_abiertos,
          },
          {
            label: 'Casos total',
            backgroundColor: '#A0D5CA',
            data: c_totales,
          }
        ]
      }
    },
    
    validar() {
        this.$refs.simpleRules.validate().then(success => {
            if (success) {
                if (!this.submitted) {
                  this.submitted = true;
                  if (this.id) {
                    this.actualizar();
                  }else{
                    this.registrar();
                  }
                }
            }
        })
    },

    date(){
      const hoy = new Date();
      var date = hoy.getFullYear()+'-'+(hoy.getMonth()+1)+'-'+hoy.getDate();
      var time = hoy.getHours()+':'+hoy.getMinutes();
      this.published_at = date + ' '+ time;
    },

    listarCategorias(){
        var url= '/admin/categorias?tipoEntradaId='+this.tipoEntradaId;
        axiosIns.get(url)
        .then(res => {
            this.categoriaOpciones=res.data.map(g => ({title:g.nombre, value: g.id}));
        })
        .catch(err =>{
            console.log(err);
        });
    },
    //categoria
    actualizarListaCategoria(item){
      this.listarCategorias();
      this.categoria_id={title:item.nombre,value:item.id};
      this.accionCategoria='';
      this.itemCategoria=[];
    },
    abriComponenteCategoria(item){
      this.accionCategoria="actualizar";
      //this.itemCategoria=item;
    },
    cerrarComponenteCategoria(){
      this.accionCategoria='';
      this.itemCategoria=[];
    },

    //RECIBIR MEDIA DEL COMPONENTE
    recibirMedia(media){
      if (media.tipo == "IMAGEN") {
        const content = `<figure class="image"><img src="${media.url}"></figure>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      } else if (media.tipo == "VIDEO") {
        if (media.enlace) {
          const content = `<figure class="media"><oembed url="${media.url}"></oembed></figure>`;
          const viewFragment = this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }else{
          const content =`
            <div class="raw-html-embed" style="text-align:center;">
              <video width="540" controls>
                <source src="${media.url}" type="video/mp4">
              </video>
            </div>`;
          const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
        }
      } else if(media.tipo=='AUDIO'){
        if (media.enlace) {
           const content =` 
            <div class="raw-html-embed" style="text-align:center;">
              <iframe src="${media.url}" width="50%" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </div>`;
          const viewFragment = this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }else{
          const content =` 
            <div class="raw-html-embed" style="text-align:center;">
              <audio controls="">
                <source src="${media.url}" type="audio/mpeg">
              </audio>
            </div>`;
          const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
        }
      } else if(media.tipo=='DOCUMENTO'){
        const content =` 
          <div class="raw-html-embed" style="text-align:center;">
            <iframe src="${media.url}" width="80%" height="720px"></iframe>
          </div>`;
        const viewFragment =
        this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
      }else{
        const content = `<a href="${media.url}">${media.titulo}</a>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      }
    },
    //RECIBIR IMAGEN DEL COMPONENTE
    recibirImagen(imagen){
      this.imagen=imagen.url;
    },

    generarDatos(){
      if (this.tipoPublicacion) {

        if (this.tipo_grafico==1) {
          var canvasEle = document.getElementById('bar-chart');
          this.imagen=canvasEle.toDataURL();
        }else{
          var canvasEle = document.getElementById('horizontalbar-chart');
          this.imagen=canvasEle.toDataURL();
        }
    
        if (this.iddepartamento==0) {
          this.departamento="Todos";
        }else{
          const departamentoAux=this.departamentoOpciones.find(departamento => departamento.id === this.iddepartamento);
          this.departamento=departamentoAux.nombre;
        }

        if (this.idmunicipio==0) {
          this.municipio="Todos";
        }else{
          const municipioAux=this.municipioOpciones.find(municipio => municipio.id === this.idmunicipio);
          this.municipio=municipioAux.nombre;
        }

        if (this.iddelito==0) {
          this.delito="Todos";
        }else{
          const delitoAux=this.delitoOpciones.find(delito => delito.id === this.iddelito);
          this.delito=delitoAux.nombre;
        }

        if (this.idclasedelito==0) {
          this.clasedelito="Todos";
        }else{
          const claseDelitoAux=this.claseDelitoOpciones.find(clasedelito => clasedelito.id === this.idclasedelito);
          this.clasedelito=claseDelitoAux.nombre;
        }

        const cargaAux=this.cargaOpciones.find(carga => carga.id === this.idcarga);
        this.carga=cargaAux.nombre;

        const tentativaAux=this.tentativaOpciones.find(tentativa => tentativa.id === this.idtentativa);
        this.tentativa=tentativaAux.nombre;

      }
    },

    //REGISTRO 
    async registrar() {
      await this.generarDatos();
      await axiosIns.post('/admin/estadisticas',{
          'titulo':this.titulo,
          'contenido':this.contenido,
          'published_at':this.published_at,
          'categoria_id':this.categoria_id.value,
          'imagen':this.imagen,
          'descripcion':this.descripcion,
          'estado':this.estado.id,
          'delito':this.delito,
          'departamento':this.departamento,
          'municipio':this.municipio,
          'carga':this.carga,
          'tentativa':this.tentativa,
          'clasedelito':this.clasedelito,
          'iddepartamento':this.iddepartamento,
          'idmunicipio':this.idmunicipio,
          'iddelito':this.iddelito,
          'idcarga':this.idcarga,
          'idtentativa':this.idtentativa,
          'idclasedelito':this.idclasedelito,
          'fechadel':this.fechadel,
          'fechaal':this.fechaal,
          'json_respuesta':JSON.stringify(this.json_respuesta),
          'json_consulta':this.json_consulta,
          'tipo_grafico':this.tipo_grafico,
          'estatico':this.estatico,
          'tipoPublicacion':this.tipoPublicacion,
      })
      .then(res => {
        this.$bvToast.toast('Exito', {
          title: 'Estadistica de guardo con exito',
          variant:'success',
          solid: false,
        })
        if (this.guardarSalir) {
          this.$router.push({ name: "estadistica" });
        }else{
          this.id=res.data
          this.$router.push({ name: "estadistica-editar",params: { id: res.data  }});
          this.editarEntrada(this.id)
        }
        this.errores=[];
        this.submitted = false;
      })
      .catch(err =>{
          this.submitted = false;
          this.errores=err.response.data.errors;
      });
    },

    async actualizar() {
      await this.generarDatos();
      await axiosIns.put('/admin/estadisticas/'+this.id,{
          'titulo':this.titulo,
          'contenido':this.contenido,
          'published_at':this.published_at,
          'categoria_id':this.categoria_id.value,
          'imagen':this.imagen,
          'descripcion':this.descripcion,
          'estado':this.estado.id,
          'delito':this.delito,
          'departamento':this.departamento,
          'municipio':this.municipio,
          'carga':this.carga,
          'tentativa':this.tentativa,
          'clasedelito':this.clasedelito,
          'iddepartamento':this.iddepartamento,
          'idmunicipio':this.idmunicipio,
          'iddelito':this.iddelito,
          'idcarga':this.idcarga,
          'idtentativa':this.idtentativa,
          'idclasedelito':this.idclasedelito,
          'fechadel':this.fechadel,
          'fechaal':this.fechaal,
          'json_respuesta':JSON.stringify(this.json_respuesta),
          'json_consulta':this.json_consulta,
          'tipo_grafico':this.tipo_grafico,
          'estatico':this.estatico,
          'tipoPublicacion':this.tipoPublicacion,
      })
      .then(res => {
        this.$bvToast.toast('Exito', {
          title: 'Noticia de guardo con exito',
          variant:'success',
          solid: false,
        })
        if (this.guardarSalir) {
          this.$router.push({ name: "estadistica" });
        }else{
          this.editarEntrada(this.id);
        }
        this.errores=[];
        this.submitted = false;
      })
      .catch(err =>{
          this.submitted = false;
          this.errores=err.response.data.errors;
      });
    },

    async editarEntrada(id){
      this.id=id;
      await axiosIns.get('/admin/estadisticas/'+this.id)
      .then(res => {
        if (res.data.estadistica) {
          this.tipoPublicacion=true;
          this.idcarga=res.data.estadistica.idcarga;
          this.iddepartamento=res.data.estadistica.iddepartamento;
          this.idclasedelito=res.data.estadistica.idclasedelito;
          if (this.idclasedelito!=0) {
            this.listarDelitosPorClase(this.idclasedelito);
          }

          this.titulo=res.data.titulo;
          this.contenido=res.data.contenido;
          this.published_at=res.data.published_at;
          this.slugCategoria=res.data.categoria.url;
          this.categoria_id={title:res.data.categoria.nombre,value:res.data.categoria.id};
          this.imagen=res.data.imagen;
          this.descripcion=res.data.descripcion;
          const valor=this.estadoOpciones.find(estado => estado.id === res.data.estado);
          this.estado={id:valor.id,title:valor.title};
          this.url=res.data.url;

          this.iddelito=res.data.estadistica.iddelito;
          this.fechadel=res.data.estadistica.fechadel;
          this.fechaal=res.data.estadistica.fechaal;
          this.json_consulta=res.data.estadistica.json_consulta;
          this.tipo_grafico=res.data.estadistica.tipo_grafico;
          this.estatico=res.data.estadistica.estatico;
          this.idmunicipio=res.data.estadistica.idmunicipio;
          this.idtentativa=res.data.estadistica.idtentativa;
          
          if (this.estatico==1) {
            if (this.idcarga==1) {
              this.generarEstadistica();
            }else{
              this.generarEstadisticaFechas();
            }
          }else{
            this.json_respuesta=JSON.parse(res.data.estadistica.json_respuesta);
            this.fillData();
          }
        }else{
          this.tipoPublicacion=false;
          this.titulo=res.data.titulo;
          this.contenido=res.data.contenido;
          this.published_at=res.data.published_at;
          this.slugCategoria=res.data.categoria.url;
          this.categoria_id={title:res.data.categoria.nombre,value:res.data.categoria.id};
          this.imagen=res.data.imagen;
          this.descripcion=res.data.descripcion;
          const valor=this.estadoOpciones.find(estado => estado.id === res.data.estado);
          this.estado={id:valor.id,title:valor.title};
          this.url=res.data.url;
        }
        
        const sha=res.data.sha;
        if (!this.guardarSalir) {
          window.open(this.mixing.app_url+'estadistica/'+this.slugCategoria+'/'+this.url+'?preview='+sha);
        }
      })
      .catch(err =>{
      });
    },

    limpiar(){
      this.date();
      this.tipoPublicacion=true;
      this.id=null;
      this.titulo='';
      this.contenido="";
      this.categoria_id="";
      this.imagen='';
      this.descripcion="";
      this.estado={ id: 2, title: 'Borrador' };
      this.url='';

      this.iddepartamento=0;
      this.iddelito=0;
      this.idcarga=1;
      this.fechadel='';
      this.fechaal='';
      this.json_respuesta=[];
      this.json_consulta='';
      this.tipo_grafico=0;
      this.estatico=0;
      this.idtentativa=0;
      this.idclasedelito=0;
      this.fillData();
    }
  },
  mounted(){
    if (this.$route.params.id) {
      this.tipoPublicacion=false;
      this.editarEntrada(this.$route.params.id);
    }
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .box-imagen{
    height: 13rem;
  }
  .box-imagen img {
      width: 100%;
      height: 100%;
  }
  .box-imagen1 img {
      object-fit: contain;
  }
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
  .vs__open-indicator {
    fill: rgba(60, 60, 60, 0.5);
  }
  .demo-inline-spacing > * {
      margin-right: 0.5rem;
      margin-top: 0rem;
  }
  .invoice-spacing {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
  }
</style>
