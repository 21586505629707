<script>
import { Bar,mixins   } from 'vue-chartjs'
const { reactiveProp } = mixins
import Chart from 'chart.js'

/*eslint-disable */

/* eslint-enable */

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  // watch: {
  //     data: function () {
  //         this.renderChart(this.data, this.options)
  //     },
  // },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
